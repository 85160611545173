import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  height: 6rem;
  width: 100%;
  background: var(--shape);
  padding: 1rem 5.2rem;

  margin-bottom: 3rem;
  
  .contentLeft {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 3.2rem;
      margin-right: 5rem;
    }

    nav {
      button {
        width: 8rem;
        height: 3rem;
        background: transparent;
        border: 0;
        
        h4 {
          color: var(--text-soft);
        }

        & + button {
          margin-left: .5rem;
        }

        transition: filter 0.2s;

        :hover {
          filter: brightness(0.7);
        }

      }
    }
  }
  .contentRight {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-left: 2rem;
      border: 0;
      background: transparent;

      img {
        height: 1.4rem;

        transition: filter 0.2s;

        :hover {
          filter: brightness(0.7);
        }
      }
    }
  }
`;

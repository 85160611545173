// import { createServer, Model } from 'miragejs';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';

// createServer({
//   models: {
//     customers: Model,
//   }, 

//   seeds(server) {
//     server.db.loadData({
//       customers: [
//         {
//           id: 1,
//           socialName: 'Bluewave',
//           fantasyName: 'Bluewavesource',
//           cnpj: '14209947000149',
//           status: 'active',
//           address: {
//             cep: '13477360',
//             state: 'SP',
//             city: 'americana',
//             neighborhood: 'santa cruz',
//             street: 'avenida joaquin boer',
//             number: '792',
//           },
//           costCenter: '...',
//           segment: '...',
//           supplierType: 'office',
//           email: 'zxnida@bluewavesource.com',
//           provider: 'owner',
//         },
//         {
//           id: 2,
//           socialName: 'The Deacons',
//           fantasyName: 'Defense',
//           cnpj: '32106900000187',
//           status: 'phase',
//           address: {
//             cep: '81870020',
//             state: 'PR',
//             city: 'curitiba',
//             neighborhood: 'pinheirinho',
//             street: 'rua antonio claudino',
//             number: '123',
//           },
//           costCenter: '...',
//           segment: '...',
//           supplierType: 'office',
//           email: 'regis@deacons.com',
//           provider: 'customer',
//         }
//       ]
//     })
//   },

//   routes() {
//     this.namespace = 'api'

//     this.get('/customers', () => {
//       return this.schema.all('customers')
//     })

//     this.post('/customers', ( schema, request ) => {
//       const data = JSON.parse(request.requestBody)

//       return schema.create('customers', data)
//     })
//   }
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

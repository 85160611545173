import { GlobalStyle } from "./styles/global";

import AppProvider from './hooks';
import { Pages } from "./pages";

export function App() {

  return (
    <AppProvider>
      <GlobalStyle />
      <Pages />
    </AppProvider>
  );
}

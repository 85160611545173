import { Container } from './styles';

// interface DataViewProps {
//
// }

export function DataView() {
  return (
    <Container>
      <div className="DataFilter">Filtros</div>
      <div className="DataView">Cabeçalho</div>
      <div className="DataGrid">
        <div className="DataBody">Tabela 1</div>
        <div className="DataBody">Tabela 2</div>
        <div className="DataBody">Tabela 3</div>
        <div className="DataBody">Tabela 4</div>
        <div className="DataBody">Tabela 5</div>
        <div className="DataBody">Tabela 6</div>
        <div className="DataBody">Tabela 7</div>
        <div className="DataBody">Tabela 8</div>
        <div className="DataBody">Tabela 9</div>
        <div className="DataBody">Tabela 0</div>
      </div>
    </Container>
  );
};

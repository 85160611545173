import logoImg from '../../assets/logo.svg';
import searchImg from '../../assets/search.svg';
import profileImg from '../../assets/profile.svg';
import logoutImg from '../../assets/logout.svg';

import { Container } from './styles'
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

interface HeaderProps {
  navigationIndex: number;
  onClickNavigation: (index: number) => void;
  navigationItems: string[],
}

export function Header({ navigationItems, navigationIndex, onClickNavigation }: HeaderProps) {
  const { singOut } = useAuth()
  const { addToast } = useToast()

  function handleSingOut() {
    addToast({
      type: 'sucess',
      title: 'Logout feito, até logo.'
    });

    setTimeout(() => {
      singOut()
    }, 3000);
  }

  return (
    <Container>
      <div className="contentLeft">
        <img src={logoImg} alt="Bluewave logo" />
        <nav>
            {navigationItems.map((item, intemIndex) => (
              intemIndex !== navigationIndex ? (
                <button
                  key={intemIndex}
                  onClick={() => onClickNavigation(intemIndex)}
                >
                <h4>{item}</h4>
              </button>
              ) : (
              <button 
                key={intemIndex}
                style={{borderBottom: "3px solid var(--blue-light)"}}
              >
                <h4>{item}</h4>
              </button>)
            ))}
        </nav>
      </div>
      <div className="contentRight">
        <button>
          <img src={searchImg} alt="Procurar" />
        </button>
        <button>
          <img src={profileImg} alt="Perfil" />
        </button>
        <button onClick={handleSingOut}>
          <img src={logoutImg} alt="Menu" />
        </button>
      </div>
    </Container>
  );
};

import { Container } from './styles';

interface CreateItemsProps {
  onSubmited: () => void;
}

export function CreateItems({ onSubmited }: CreateItemsProps) {
  return (
    <Container>
      <h1>CreateItems</h1>
    </Container>
  );
};

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useRef } from 'react';
import { Input } from '../../../components/Inputs';
import Select from '../../../components/Select';
import { Container, FormStyle } from './styles';

interface CreateRequisitionProps {
  onSubmited: () => void;
}

export function CreateRequisition({ onSubmited }: CreateRequisitionProps) {
  const formRef = useRef<FormHandles>(null)
  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Container>
        <FormStyle>
          <div className="CategorySegmentProduct" id="DivGrid">
            <p>Categoria</p>
            <p>Segmento do produto</p>
            <Select name="category" />
            <Select name="segmentProdut" />
          </div>
          <div className="ItemItemCode" id="DivGrid">
            <p>Item</p>
            <p>Codigo</p>
            <Input name="item" />
            <Input name="itemCode" />
          </div>
          <div className="UmdQtdCostUnitCurrency" id="DivGrid">
            <p>Tipo de unidade</p>
            <p>Quantidade</p>
            <p>Valor unitario</p>
            <p>Moeda</p>
            <Select name="umd" />
            <Input name="qtd" />
            <Input name="costUnit" />
            <Select name="currency" />
          </div>
          <div className="TotalDeadline" id="DivGrid">
            <p>Valor total</p>
            <p>Prazo Ideal</p>
            <Input name="total" />
            <Input name="deadline" />
          </div>
          <h3>Anexos:</h3>
          <div className="Anexos">clique ou arraste os anexos nessa area</div>         
          <button className="Submit" type="submit">Concluir Cadastro</button>
        </FormStyle>
      </Container>
    </Form>
  );
};

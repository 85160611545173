import styled from 'styled-components';

export const Container = styled.div`
  width: 52.93rem;
  margin-top: 6rem;
`;

export const FormStyle = styled.div`
  input, button {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--text-soft);
    
    background: #F8F8F8;
    
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;
    
    & + input {
      margin-top: 1rem;
    }    
  }
  .EmailAssigning {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 1.2rem;
  }

  .Submit {
    height: 5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background: var(--green);
    color: #F8F8F8;
    font-size: 1.4rem;
    font-weight: 600;

    transition: filter 0.2s;

    :hover {
      filter: brightness(1.2);
    }
  }
`;

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useRef } from 'react';
import { Input } from '../../../components/Inputs';
import Select from '../../../components/Select';
import { optionAssignings } from '../../../types';
import { Container, FormStyle } from './styles';

interface CreateFunctionaryProps {
  onSubmited: () => void;
}

export function CreateFunctionary({ onSubmited }: CreateFunctionaryProps) {
  const formRef = useRef<FormHandles>(null)
  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Container>
        <FormStyle>
          <Input
            name="fullName" 
            placeholder="Nome completo"
          />
          <div className="EmailAssigning">
            <Input
              name="email"
              type="email"
              placeholder="Email"
            />
            <Select
                name="assigning"
                options={optionAssignings}
                placeholder="Acesso"
              />
          </div>
          <button className="Submit" type="submit">Concluir Cadastro</button>
        </FormStyle>
      </Container>
    </Form>
  );
};

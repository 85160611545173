import { useRef, useEffect, ChangeEvent } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
interface Props extends InputProps {
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const onlyNumbers = (value: string): string => value.replace(/[^0-9]/g, "")

export function InputMask({ name,  onChange,...rest }: Props) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (!onChange) return
    onChange({
      ...event,
      target: {
        ...event.target,
        value: onlyNumbers(event.target.value)
      }
    })
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <ReactInputMask ref={inputRef} onChange={handleChange} defaultValue={defaultValue} {...rest} />
  );
};
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --blue-strong: #062A40;
    --blue-light: #8ACAED;
    --green: #009881;
    --text-soft: #868C90;
    --background: #F3F5F9;
    --shape: #ffffff;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    overflow-y: hidden;
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.7);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    overflow-y: scroll;
    max-width: 760px;
    max-height: 628px;
    background: var(--background);
    padding: 3rem;
    padding-right: 2.63rem;
    position: relative;
    border-radius: 0.25rem;

    display: inline-block;

    &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
    &::-webkit-scrollbar-thumb:hover{
      background: rgba(0,0,0,0.4);
    }
    &::-webkit-scrollbar-thumb:active{
      background: rgba(0,0,0,.6);
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background-color: transparent;
  }

  .react-select {
    font-family: 'Roboto', sans-serif;
    
    .inner__value-container {
      height: 2.7rem;
      padding-left: 1.5rem;
      align-items: center;
      align-content: center;

      div {
        &:first-child {
          top: 52%;
          font-size: 1.2rem;
          color: var(--text-soft)
        }
      }
    }
  }
  

`
export type employerNavigation = 'Requisições'| 'Fornecedores'| 'Clientes'| 'Items';
export const employerNavigationItems: employerNavigation[] = ['Requisições', 'Fornecedores', 'Clientes', 'Items'];

export type ownerNavigation = 'Colaboradores' | 'Requisições'| 'Fornecedores'| 'Clientes'| 'Items';
export const ownerNavigationItems: ownerNavigation[] = [ 'Colaboradores', 'Requisições', 'Fornecedores', 'Clientes', 'Items'];

export type Providers = 'customer' | 'functionary' | 'vendor' | 'owner'

export interface Address {
  cep: string
  state: string
  city: string
  neighborhood: string
  street: string
  number: string
}
export interface Company {
  id?: string;
  socialName: string;
  fantasyName: string;
  cnpj: string;
  status: string;
  address: Address;
  costCenter: string;
  segment: string;
  supplierType: string;
  email: string;
  provider: string;
}


export interface Vendor {
  id?: number;
  userId: string;
  socialName: string;
  fantasyName: string;
  cnpj: string;
  status: string;
  address: Address;
  email: string;
}

export const optionStatus = [
  { value: 'new', label: 'Novo' },
  { value: 'phase', label: 'Em fase de aprovação' },
  { value: 'active', label: 'Ativo' },
  { value: 'inactive', label: 'Inativo' }
]

export const optionSuppliers = [
  { value: 'office', label: 'Escritorio' },
  { value: 'factory', label: 'Fábrica' },
  { value: 'resale', label: 'Revenda' },
  { value: 'distribution', label: 'Centro de distribuição' },
]

export const optionAssignings = [
  { value: 'master', label: 'Master'},
  { value: 'keyuser', label: 'Keyuser'},
]

export const optionVendorStatus = [
  { value: 'evidential', label: 'Probatório'},
  { value: 'listed', label: 'Listado'},
  { value: 'preferred', label: 'Preferido'},
  { value: 'partner', label: 'Parceiro'},
  { value: 'inactive', label: 'inactive'},
]
import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 3rem;
  height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  background: var(--shape);

  height: 100%;
  width: 88%;

  padding: 2rem 0;
  border-radius: 0.5rem;

  padding-top: 0;

  header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.2rem;

    justify-content: center;
    align-items: center;

    .topHeaderPage {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      div {
        width: 300px;
        padding: 0 2rem;
      }

      button {
        width: 100%;
        height: 2rem;
        font-size: .9rem;
        font-weight: 600;
        color: #F3F5F9;
        border: 0;
      }

      .ContentLeft {
        button  {
          width: 1.2rem;
          display: flex;
          align-items: center;
          background: transparent;

          img {
            height: 1.2rem;

            transition: filter 0.2s;

            :hover {
              filter: brightness(0.6);
            }
          }
        }
      }

      .ContentCenter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        h3 {
          color: var(--blue-strong)
        }
      }

      .ContentRight {
        button {
          background: var(--blue-strong);
          border-radius: 0.25rem;

          transition: filter 0.2s;

          :hover {
            filter: brightness(1.6);
          }
        }
      }
      
    }
    
    #lineH {
      width: 100%;
      margin-top: 1rem;
      border: 1px solid #F3F5F9;
      border-radius: 0.5rem;
    }
  }
`;

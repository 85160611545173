import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  height: 62vh;
  margin-top: 3rem;
  padding: 0 16rem;

  h3 {
    margin-top: 2rem;
    color: var(--text-soft);
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
    &::-webkit-scrollbar-thumb:hover{
      background: rgba(0,0,0,0.4);
    }
    &::-webkit-scrollbar-thumb:active{
      background: rgba(0,0,0,.6);
  }
`;

export const FormStyle = styled.div`
  input, button {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--text-soft);
    
    background: #F8F8F8;
    
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;
    
    & + input {
      margin-top: 1rem;
    }    
  }

  .Anexos {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    padding: 1;
    border: 4px solid var(--text-soft);
    border-style: dotted;
    border-radius: 0.38rem;
  }

  #DivGrid {
    p {
      margin-bottom: -1rem;
      font-weight: 600;
      color: var(--text-soft)
    }

    & + div {
      margin-top: 1.5rem;
    }
  }

  .CategorySegmentProduct {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    gap: 1.2rem;
  }

  .ItemItemCode {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.2rem;

    input {
      margin: 0;
    }
  }

  .UmdQtdCostUnitCurrency {
    display: grid;
    grid-template-columns: .8fr 1fr .9fr .6fr;
    gap: 1.2rem;

    input {
      margin: 0;
    }
  }

  .TotalDeadline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;

    input {
      margin: 0;
    }
  }

  .Submit {
    height: 5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background: var(--green);
    color: #F8F8F8;
    font-size: 1.4rem;
    font-weight: 600;

    transition: filter 0.2s;

    :hover {
      filter: brightness(1.2);
    }
  }
`;

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import logoImg from '../../assets/session.svg'
import { Container } from './styles';
import { Input } from '../../components/Inputs';

import { getValidationErrors } from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

interface SingInFormData {
  email: string;
  password: string;
}

export function Sesssion() {
  const formRef = useRef<FormHandles>(null);

  const { singIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SingInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().required('Senha obrigatória')
        });

        await schema.validate(data, {
          abortEarly: false
        });

        await singIn({
          email: data.email,
          password: data.password
        });

        addToast({
          type: 'sucess',
          title: 'Login feito com sucesso'
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer o login, cheque suas credenciais'
        });
      }
    },
    [singIn, addToast]
  );

  return (
    <Container>
      <img src={logoImg} alt="Logo" />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="ContentSessionForm">
          <Input
            placeholder="E-mail"
            
            type="email"
            name="email"/>
          <Input
            placeholder="Senha"
            type="password"
            name="password"/>

          <a href="/forgot">Esqueceu sua senha?</a>
          
          <button id="SubmitSessionForm" type="submit">ENTRAR</button>
        </div>
      </Form>
    </Container>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  height: 62vh;
  margin-top: 2rem;
  padding: 0 16rem;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
    &::-webkit-scrollbar-thumb:hover{
      background: rgba(0,0,0,0.4);
    }
    &::-webkit-scrollbar-thumb:active{
      background: rgba(0,0,0,.6);
    }

  h2 {
    color: var(--blue-strong);
  }
`;

export const FormStyle = styled.div`
  .Cnpj-Status {
    display: grid;
    grid-template-columns: 1.1fr 2fr;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    
    margin: 1rem 0;

    input {
      margin: 0;
    }
  }
  
  .Cep-Numero {
    display: grid;
    grid-template-columns: 1.1fr 3.5fr;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    
    margin: 1rem 0;
    
    input {
      margin: 0;
    }
  }
  
  .Bairro-Numero {
    display: grid;
    grid-template-columns: 4fr 1.2fr;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    
    margin: 1rem 0;
    
    input {
      margin: 0;
    }
  }

  .Uf-Cidade {
    display: grid;
    grid-template-columns: 1fr 7fr;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    
    margin: 1rem 0;
    
    input {
      margin: 0;
    }
  }

  .TDF {
    margin: 1rem 0;
  }
  
  h2 {
    color: var(--blue-strong);
  }
  
  input, button {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--text-soft);
    
    background: #F8F8F8;
    
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;
    
    & + input {
      margin-top: 1rem;
    }
  }

  .Submit {
    height: 5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background: var(--green);
    color: #F8F8F8;
    font-size: 1.4rem;
    font-weight: 600;

    transition: filter 0.2s;

    :hover {
      filter: brightness(1.2);
    }
  }
  
  h3 {
    margin: 2rem 0 1rem;
    color: var(--text-soft);
  }

  #segment {
    margin-bottom: 1rem;
  }
`;

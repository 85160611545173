import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { useRef, useState } from 'react';
import { InputMask } from '../../../components/ImputMask';
import { Input } from '../../../components/Inputs';
import Select from '../../../components/Select';
import { api } from '../../../services/api';
import { brasilApi } from '../../../services/brasilApi';
import { optionVendorStatus, Vendor } from '../../../types';
import { Container, FormStyle } from './styles';

interface CompanyData {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  cep: string
  uf: string
  municipio: string
  bairro: string
  logradouro: string
  numero: string
}

interface CreateSupplierProps {
  onSubmited: () => void;
}

export function CreateSupplier({ onSubmited }: CreateSupplierProps) {

  const formRef = useRef<FormHandles>(null);
  const [cnpj, setCnpj] = useState('')
  const [company, setCompany] = useState<Vendor>({address: {}} as Vendor)

  async function handleOnBlurCnpj() {
    if (cnpj.length >= 14) {
      const { data } = await brasilApi.get<CompanyData>(`${cnpj}`);

      const { 
        razao_social: socialName,
        nome_fantasia: fantasyName,
        cep,
        municipio: city,
        logradouro: street,
        bairro: neighborhood,
        uf: state,
        numero: number
      } = data;

      setCompany({
        ...company,
        socialName,
        fantasyName,
        cnpj,
        address: {
          cep,
          city,
          neighborhood,
          street,
          state,
          number 
        }
      })
    }
  }

  async function handleSubmit(data: Vendor) {
    await api.post('/customers', data)
    
    setCnpj('')
    setCompany({address: {}} as Vendor)
    onSubmited()
  }
  
  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Container>
        <FormStyle>
          <div className="Cnpj-Status">
            <InputMask
              name="cnpj"
              placeholder="Cnpj"
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={event => setCnpj(event.target.value)}
              onBlur={handleOnBlurCnpj}
            />
            <Select name="status" placeholder="Status" options={optionVendorStatus} />
          </div>
          <Input name="socialName" placeholder="Razão Social" value={company.socialName} />
          <Input name="fantasyName" placeholder="Nome de Fantasia" value={company.fantasyName} />
          <h3>Endereço</h3>
          <Scope path="address">
            <div className="Cep-Numero">
              <InputMask
                name="cep"
                placeholder="CEP"
                mask="99.999-999"
                value={company.address.cep}
                onChange={event => setCompany({
                  ...company,
                  address: {
                    ...company.address,
                    cep: event.target.value
                  }
                })}
              />
              <Input
                name="street"
                placeholder="Rua"
                value={company.address.street}
                onChange={event => setCompany({
                  ...company,
                  address: {
                    ...company.address,
                    street: event.target.value
                  }
                })}
              />
            </div>
            <div className="Bairro-Numero" >
              <Input
                name="neighborhood"
                placeholder="Bairro"
                value={company.address.neighborhood}
                onChange={event => setCompany({
                  ...company,
                  address: {
                    ...company.address,
                    neighborhood: event.target.value
                  }
                })}
              />
              <Input
                name="number"
                placeholder="Numero"
                value={company.address.number}
                onChange={event => setCompany({
                  ...company,
                  address: {
                    ...company.address,
                    number: event.target.value
                  }
                })}
              />
            </div>
            <div className="Uf-Cidade">
              <Input
                name="state"
                placeholder="UF"
                value={company.address.state}
                onChange={event => setCompany({
                  ...company,
                  address: {
                    ...company.address,
                    state: event.target.value
                  }
                })}
              />
              <Input
                name="city"
                placeholder="Cidade"
                value={company.address.city}
                onChange={event => setCompany({
                  ...company,
                  address: {
                    ...company.address,
                    city: event.target.value
                  }
                })}
              />
            </div>
          </Scope>
          <h3>Administrador</h3>
          <Input
            name="email"
            placeholder="E-mail"
            value={company.email}
            onChange={event => setCompany({
              ...company,
              email: event.target.value
            })}
          />
          <button className="Submit" type="submit">Concluir Cadastro</button>
        </FormStyle>
      </Container>
    </Form>
  );
};

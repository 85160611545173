import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  div {
    margin: 2rem 2rem 1rem;
    color: var(--text-soft)
  }

  .DataFilter {
    margin-right: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7rem;

    border: 2px solid var(--text-soft);
    border-radius: .35rem;
    border-style: dashed;
  }

  .DataView {
    margin: 0 2rem;
    margin-right: 2.4rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 2px solid var(--text-soft);
    border-radius: .35rem;
    border-style: dashed;
  }
  
  .DataGrid {
    overflow-y: scroll;
    width: 100%;
    height: 40vh;
    margin: 0;

    &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0,0,0,0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba(0,0,0,.6);
    }

    .DataBody {
    margin: .5rem 2rem;
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 2px solid var(--text-soft);
    border-radius: .35rem;
    border-style: dashed;
  }
  }

`;

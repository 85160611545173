import { Container, Body } from './styles';

import { CreateCustomers } from '../_forms/CreateCustomers';
import { CreateRequisition } from '../_forms/CreateRequisition';
import { CreateSupplier } from '../_forms/CreateSupplier';
import { CreateItems } from '../_forms/CreateItems';
import { DataView } from '../../components/DataView'
import backImg from '../../assets/back.svg'
import { ownerNavigation, ownerNavigationItems } from '../../types';
import { useState } from 'react';
import { Header } from '../../components/Header';
import { CreateFunctionary } from '../_forms/CreateFunctionary';

export function OwnerDashboard() {
  const [navigationIndex, setNavigationIndex] = useState(0)
  const [item, setItem] = useState<ownerNavigation>(ownerNavigationItems[navigationIndex])
  const [aboutPage, setAboutPage] = useState<string>(item)

  const [registrationButton, setRegistrationButton] = useState(true)

  const [isNewfunctionaryFormOpen, setIsNewfunctionaryFormOpen] = useState(false);
  const [isNewCustomerFormOpen, setIsNewCustomerFormOpen] = useState(false);
  const [isNewVendorFormOpen, setIsNewVendorFormOpen] = useState(false);
  const [isNewRequisitionFormOpen, setIsNewRequisitionFormOpen] = useState(false);
  const [isNewItemFormOpen, setIsNewItemFormOpen] = useState(false);

  const [ dataGridOpen, setDataGridOpen] = useState(true);

  function handleClickNavigation(index: number) {
    if (
      isNewfunctionaryFormOpen ||
      isNewCustomerFormOpen ||
      isNewVendorFormOpen ||
      isNewRequisitionFormOpen ||
      isNewItemFormOpen) {
      return
    }
    setNavigationIndex(index)
    setItem(ownerNavigationItems[index])
    setAboutPage(ownerNavigationItems[index])
  }

  function handleClickCreateNewResgistration() {
    switch (item) {
      case 'Colaboradores':
        setDataGridOpen(false)
        setIsNewfunctionaryFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Colaborador')
        break;
      case 'Clientes':
        setDataGridOpen(false)
        setIsNewCustomerFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Cliente')
        break;
      case 'Fornecedores':
        setDataGridOpen(false)
        setIsNewVendorFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Fornecedor')
        break;
      case 'Requisições':
        setDataGridOpen(false)
        setIsNewRequisitionFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Requisição')
        break;
      case 'Items':
        setDataGridOpen(false)
        setIsNewItemFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Item')
        break;
    }
  }

  function handleCloseCreateNewResgistration() {
    switch (item) {
      case 'Colaboradores':
        setDataGridOpen(true)
        setIsNewfunctionaryFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Clientes':
        setDataGridOpen(true)
        setIsNewCustomerFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Fornecedores':
        setDataGridOpen(true)
        setIsNewVendorFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Requisições':
        setDataGridOpen(true)
        setIsNewRequisitionFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Items':
        setDataGridOpen(true)
        setIsNewItemFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
    }
  }
  return (
    <Container>
      <Header
        onClickNavigation={handleClickNavigation}
        navigationIndex={navigationIndex}
        navigationItems={ownerNavigationItems}
      />
      <Body>
        <header>
          <div className="topHeaderPage">
            <div className="ContentLeft">
              {registrationButton ? <></> : (<button onClick={handleCloseCreateNewResgistration}>
                <img src={backImg} alt="Voltar" />
              </button>)}
            </div>
            <div className="ContentCenter">
              <h3>{aboutPage}</h3>
            </div>
            <div className="ContentRight">
              {registrationButton
                ? (<button onClick={handleClickCreateNewResgistration} >Novo Cadastro</button>)
                : <></>
              }
            </div>
          </div>
          <div id="lineH" />
        </header>
        {dataGridOpen ? <DataView /> : <></>}
        {isNewfunctionaryFormOpen ? (
          <CreateFunctionary
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewRequisitionFormOpen ? (
          <CreateRequisition
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewVendorFormOpen ? (
          <CreateSupplier
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewCustomerFormOpen ? (
          <CreateCustomers
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewItemFormOpen ? (
          <CreateItems
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
      </Body>
    </Container>
  );
};

import React, {
  createContext, useCallback, useState, useContext, useEffect
} from 'react';
import { api } from '../services/api';

type User = {
  id: string,
  full_name?: string,
  company_id?: string,
  role?: 'customer' | 'functionary' | 'vendor' | 'owner',
  assigning?: 'master' | 'keyuser',
}

type Token = {
  type: "bearer",
  token: string,
  expires_at: Date,
}

interface Response {
  token: Token;
  user: User;
}

interface SingInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  singIn(credentials: SingInCredentials): Promise<void>;
  singOut(): void;
}

interface AuthState {
  token: string;
  user: User;
}

const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Bluewavesource:token');
    const user = localStorage.getItem('@Bluewavesource:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  useEffect(() => {
    if(data.user) {
      api.get<User>(`users/${data.user.id}`).then(response => setData({...data, user: response.data}))
    }
    console.log(data)
  }, [data])

  const singIn = useCallback(async ({ email, password }) => {
    const response = await api.post<Response>('session', {
      email,
      password
    });

    const { token: Token, user } = response.data;

    localStorage.setItem('@Bluewavesource:token', Token.token);
    localStorage.setItem('@Bluewavesource:user', JSON.stringify(user));

    const token = Token.token

    setData({ token, user });
  }, []);

  const singOut = useCallback(() => {
    localStorage.removeItem('@Bluewavesource:token');
    localStorage.removeItem('@Bluewavesource:user');

    setData({} as AuthState);
    window.location.reload()
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, singIn, singOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--blue-light);

  img {
    max-width: 522px;
  }

  .ContentSessionForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 4rem;

    a {
      margin-top: 1.8rem;
      margin-bottom: 3.5rem;
      
      font-family: 'Roboto', sans-serif;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--shape);
      display: block;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#8ACAED')}
      }
    }

    input, button {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      color: var(--shape);
      
      background: var(--blue-light);
      
      width: 20rem;
      height: 2.8rem;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      padding: 0 1.5rem;
      border: .08rem solid var(--shape);
      border-radius: 0.32rem;
        
      & + input {
        margin-top: 1.5rem;
      }

      &::placeholder {
        color: var(--shape);
      }
    }

    #SubmitSessionForm {
      font-size: 1.2rem;
      font-weight: 500;
      height: 3.1rem;
      border: 0;
      border-radius: 0.75rem;
      background: var(--blue-strong);

      margin-bottom: 4rem;
    }
  }
`;

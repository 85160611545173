import { useState } from 'react';

import { Header } from '../../components/Header';
import { Container, Body } from './styles';
import { CreateCustomers } from '../_forms/CreateCustomers';
import { CreateRequisition } from '../_forms/CreateRequisition';
import { CreateSupplier } from '../_forms/CreateSupplier';
import { CreateItems } from '../_forms/CreateItems';
import backImg from '../../assets/back.svg'

import { employerNavigation, employerNavigationItems } from '../../types';

export function EmployersDashboard() {
  const [navigationIndex, setNavigationIndex] = useState(0)
  const [item, setItem] = useState<employerNavigation>(employerNavigationItems[navigationIndex])
  const [aboutPage, setAboutPage] = useState<string>(item)

  const [registrationButton, setRegistrationButton] = useState(true)

  const [isNewCustomerFormOpen, setIsNewCustomerFormOpen] = useState(false);
  const [isNewVendorFormOpen, setIsNewVendorFormOpen] = useState(false);
  const [isNewRequisitionFormOpen, setIsNewRequisitionFormOpen] = useState(false);
  const [isNewItemFormOpen, setIsNewItemFormOpen] = useState(false);

  function handleClickNavigation(index: number) {
    if (
      isNewCustomerFormOpen ||
      isNewVendorFormOpen ||
      isNewRequisitionFormOpen ||
      isNewItemFormOpen) {
      return
    }
    setNavigationIndex(index)
    setItem(employerNavigationItems[index])
    setAboutPage(employerNavigationItems[index])
  }

  function handleClickCreateNewResgistration() {
    switch (item) {
      case 'Clientes':
        setIsNewCustomerFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Cliente')
        break;
      case 'Fornecedores':
        setIsNewVendorFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Fornecedor')
        break;
      case 'Requisições':
        setIsNewRequisitionFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Requisição')
        break;
      case 'Items':
        setIsNewItemFormOpen(true)
        setRegistrationButton(false)
        setAboutPage('Cadastrar Item')
        break;
    }
  }

  function handleCloseCreateNewResgistration() {
    switch (item) {
      case 'Clientes':
        setIsNewCustomerFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Fornecedores':
        setIsNewVendorFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Requisições':
        setIsNewRequisitionFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
      case 'Items':
        setIsNewItemFormOpen(false)
        setRegistrationButton(true)
        setAboutPage(item)
        break;
    }
  }

  return (
    <Container>
      <Header
        onClickNavigation={handleClickNavigation}
        navigationIndex={navigationIndex}
        navigationItems={employerNavigationItems}
      />
      <Body>
        <header>
          <div className="topHeaderPage">
            <div className="ContentLeft">
              {registrationButton ? <></> : (<button onClick={handleCloseCreateNewResgistration}>
                <img src={backImg} alt="Voltar" />
              </button>)}
            </div>
            <div className="ContentCenter">
              <h3>{aboutPage}</h3>
            </div>
            <div className="ContentRight">
              {registrationButton
                ? (<button onClick={handleClickCreateNewResgistration} >Novo Cadastro</button>)
                : <></>
              }
            </div>
          </div>
          <div id="lineH" />
        </header>
        {isNewRequisitionFormOpen ? (
          <CreateRequisition
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewVendorFormOpen ? (
          <CreateSupplier
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewCustomerFormOpen ? (
          <CreateCustomers
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
        {isNewItemFormOpen ? (
          <CreateItems
            onSubmited={handleCloseCreateNewResgistration}
          />
        ) : <></>}
      </Body>
    </ Container>
  );
};

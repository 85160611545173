import { Sesssion } from './Sesssion';
import { EmployersDashboard } from "./EmployersDashboard";

import { useAuth } from '../hooks/auth';
import { CustomersDashboard } from './CustomersDashboard';
import { VendorsDashboard } from './VendorsDashboard';
import { OwnerDashboard } from './OwnerDashboard';

export function Pages() {
  const { user } = useAuth();

  return (
    <>
      { !user ? <Sesssion /> : (
        user.role === 'owner' ? <OwnerDashboard /> : (
          user.role === 'functionary' ? <EmployersDashboard /> : (
            user.role === 'customer' ? <CustomersDashboard /> : (
              user.role === 'vendor' ? <VendorsDashboard /> : <></>
            )
          )
        )
      )}
    </>
  );
}
